import React from "react";
import { Link } from "react-router-dom";

import underConstruction from "../assets/underConstruction.png";
import "./styles/App.css";

function App() {
  return (
    <div className="fullscreen">
      <div className="app">
        <h2 className="header-font">Greenwise Rides Will Return Soon...</h2>
        <h4 className="header-font">
          We're working on our site in preparation for 2020!
        </h4>
        <img
          src={underConstruction}
          className="construction"
          alt="construction"
        />
        <h4 className="header-font">
          Until then, please feel free to contact us by phone at 850-591-5737 or
          by email greenwiserides@embarqmail.com!
        </h4>
        <h4 className="header-font">
          Or come visit us in person at: 337 Ross Road Tallahassee, Florida
        </h4>
      </div>
    </div>
  );
}

export default App;
